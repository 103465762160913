import axios from 'axios';
import {IEpicSchedulingDataService} from "../Interfaces/IEpicSchedulingDataService";
import { Provider } from "../Models/Provider";

export class EpicSchedulingDataService implements IEpicSchedulingDataService {

    async GetProviderData(id: string): Promise<Provider> {
        let provider: Provider = undefined;

        await axios.get(`/api/providers/${id}?fields=Profiles`).then(response => {
           provider = response.data;
        }).catch(error => {
            console.error('Failed to get provider data', error);
        });

        return provider;
    }

}
