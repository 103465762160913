import { IEpicSchedulingConfig } from "../../Interfaces/Configuration/IEpicSchedulingConfig";

export class Configuration implements IEpicSchedulingConfig {
    ApiKey: string = '';
    ButtonClasses: Array<string> = [];
    DebugMode: boolean = false;
    EpicEnabledFacet: string = 'Epic';
    EpicHost: string = '';
    EpicInstance: string = '';
    EpicDisabledMessage: string = "Not accepting appointments at this time."
    MultipleVisitTypesMessage: string = 'Select a Visit Type';
    LinkSource: string = 'widget';
    ScriptPath: string = 'Scripts/lib/Widget/widget_sdk.js';
    TrackingSource: string = '';
    WidgetType: string = 'openscheduling';
    OuterContainerId: string = 'EpicSchedulingContainer';
    InnerContainerId: string = 'EpicSchedulingContent';
    EmbedContainerId: string = 'EpicSchedulingEmbed';
}
