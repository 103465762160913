import { IEpicSchedulingConfig } from "../Interfaces/Configuration/IEpicSchedulingConfig";
import { GetSiblings } from "../Helpers/helpers";

export class EpicSchedulingService {
    private readonly Configuration: IEpicSchedulingConfig;

    constructor(config: IEpicSchedulingConfig) {
        this.Configuration = config;
    }

    public AddScripts(script) {
        return new Promise((resolve, reject) => {
            script.addEventListener('load', () => resolve(script), false);
            script.addEventListener('error', () => reject(script), false);
            document.head.appendChild(script);
        });
    }

    public HandleMultipleVisitTypes(epicId: string, vtId: string, vtLabel: string, departmentId: string, enabled: boolean): HTMLButtonElement {
        if (enabled) {
            let button = document.createElement('button');
                button.innerHTML = vtLabel;
                button.dataset.visitType = vtId;
                button.setAttribute('aria-pressed', 'false');
                button.classList.add('VisitTypeButton');

            this.Configuration.ButtonClasses.forEach((className) => {
                button.classList.add(className);
            });

            // Handle click.
            button.addEventListener('click', (event) => {
                this.HandleMultipleVisitButtonClick(event, epicId, vtId, departmentId, enabled);
            });

            return button;
        }
    }

    public HandleMultipleVisitButtonClick(event: Event, epicId: string, vtId: string, departmentId: string, enabled: boolean) {
        event.preventDefault();
        let element = event.currentTarget as HTMLButtonElement;
        vtId = element.dataset.visitType;
        element.setAttribute('aria-pressed', 'true');
        let siblings = GetSiblings(element);
        siblings.forEach((sibling) => {
            sibling.tagName === 'BUTTON' ? (sibling.ariaPressed = 'false') : '';
        });
        let container: HTMLElement = document.getElementById(this.Configuration.InnerContainerId);
        let el = document.getElementById(this.Configuration.EmbedContainerId);
        el ? el.remove() : null;
        container.appendChild(this.GenerateEpicSchedulingWidget(enabled));
        this.CallRenderWidgetScript(epicId, vtId, departmentId);
    }

    public GenerateEpicSchedulingWidget(enabled: boolean): HTMLElement {
        if (enabled) {
            let embedContainerHtml: HTMLElement = document.createElement('div');
                embedContainerHtml.id = this.Configuration.EmbedContainerId;
            return embedContainerHtml;
        }
    }

    public CallRenderWidgetScript(epicId: string, vtId: string, departmentId: string) {
        let url = `https://${this.Configuration.EpicHost}/${this.Configuration.EpicInstance}`;
        let apiKey = this.Configuration.ApiKey;
        let widgetType = this.Configuration.WidgetType;
        let containerId = this.Configuration.EmbedContainerId;
        let linksource = this.GenerateLinkSource(this.Configuration.LinkSource, this.Configuration.TrackingSource);

        epicId = epicId ? epicId : '';
        departmentId = departmentId ? departmentId : '';
        vtId = vtId ? vtId : '';

        (window as any).mychartWidget.renderWidget({
            "url": url,
            "apiKey": apiKey,
            "widgetType": widgetType,
            "containerId": containerId,
            "additionalParams": {
                "vt": vtId,
                "dept": departmentId,
                "id": epicId,
                "passthroughparam-linksource": linksource
            }
        });
    }

    public GenerateLinkSource(source, tracking) {
        let urlQuery: string = this.Configuration.DebugMode ? '?query=oranges' : window.location.search;
        if (urlQuery) {
            let queryParams = new URLSearchParams(urlQuery);
            if (queryParams.has(tracking)) {
                let value: string = queryParams.get(tracking);
                return 'widget_' + value;
            } else {
                return source;
            }
        } else {
            return source;
        }
    }
}
