import { IEpicSchedulingDataService } from "../Interfaces/IEpicSchedulingDataService";
import { Provider } from "../Models/Provider";

export class StaticEpicSchedulingDataService implements IEpicSchedulingDataService {

    async GetProviderData(id: string): Promise<Provider> {
        let departmentId = {
            "ProfileData": "<p>100517001</p>",
            "ProfileId": "b3978162-f3b3-4b0e-affa-4c8e3aebe7d7",
            "Type": "Department ID"
        }
        let epicId = {
            "ProfileData": "<p>238</p>",
            "ProfileId": "ea03248a-3628-44db-b462-87d4543c4907",
            "Type": "Epic ID"
        }
        let visitTypeData = id === '12' ? '<p>1234</p>' : '<p>1001 [New Appointment Test], 1002 [Established Patient Test]</p>';
        let visitType = {
            "ProfileData": visitTypeData,
            "ProfileId": "5d9a53ce-6656-40a0-86cb-e24985a57ea6",
            "Type": "Visit Type"
        }
        return {
            "Value": {
                "Profiles": {
                    "DepartmentID": departmentId,
                    "EpicID": epicId,
                    "VisitType": visitType
                }
            }
        };
    }
}
