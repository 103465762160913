import { Configuration } from "./Models/Configuration/Configuration";
import { IEpicSchedulingService } from "./Interfaces/IEpicSchedulingService";
import { EpicSchedulingService } from "./Services/EpicSchedulingService";
import { IEpicSchedulingDataService } from "./Interfaces/IEpicSchedulingDataService";
import { EpicSchedulingDataService } from "./Services/EpicSchedulingDataService";
import { StaticEpicSchedulingDataService } from "./Services/StaticEpicSchedulingDataService";
import { Profiles } from "./Models/Provider";
import { ExtractContent } from "./Helpers/helpers";

export class Application {
    private readonly Configuration: Configuration;
    private EpicSchedulingService: IEpicSchedulingService;
    private EpicSchedulingDataService: IEpicSchedulingDataService;

    constructor(config: Configuration) {
        this.Configuration = new Configuration();
        Object.assign(this.Configuration, config);
        this.EpicSchedulingService = new EpicSchedulingService(this.Configuration);
        this.EpicSchedulingDataService = this.Configuration.DebugMode
            ? new StaticEpicSchedulingDataService()
            : new EpicSchedulingDataService();
    }

    public RenderEpicSchedulingWidget(vitalSiteObject: any) {
        // Check for required settings.
        const apiKey: string = this.Configuration.ApiKey;
        const widgetType: string = this.Configuration.WidgetType;
        const scriptPath: string = this.Configuration.ScriptPath;

        if (apiKey && widgetType && scriptPath) {
            // Set div ids to look for.
            let outerContainer: HTMLElement = document.getElementById(this.Configuration.OuterContainerId);
            let embedContainer: HTMLElement = document.getElementById(this.Configuration.EmbedContainerId);

            if (outerContainer || embedContainer) {
                // Check for Epic URL parts.
                let epicHost: string = this.Configuration.EpicHost;
                let epicInstance: string = this.Configuration.EpicInstance;

                if (epicHost && epicInstance) {
                    // Create script.
                    let src = `https://${epicHost}/${epicInstance}/${scriptPath}`;
                    let script = document.createElement('script');
                    script.type = 'text/javascript';
                    script.src = src;

                    let hasRendered = false;

                    // Add Epic script to page.
                    this.EpicSchedulingService.AddScripts(script).then(() => {
                        if (outerContainer) {
                            // Create div.
                            let innerContainerDiv: HTMLElement = document.createElement('div');
                            innerContainerDiv.id = this.Configuration.InnerContainerId;
                            // If page has taxonomy.
                            if (vitalSiteObject.taxonomy) {
                                let epicEnabledFacet = this.Configuration.EpicEnabledFacet.replace(/\s/g,'');
                                let enabled = vitalSiteObject.taxonomy.find((facet) => {
                                    return facet.FacetPropertyName === epicEnabledFacet && facet.Terms[0].Name === 'Enabled';
                                });
                                // If Provider.
                                if (vitalSiteObject.provider) {
                                    // Look up provider id and if enabled.
                                    let providerId = vitalSiteObject.provider.ProviderId.toString();
                                    if (enabled) {
                                        // Add Epic Widget to target.
                                        outerContainer.appendChild(innerContainerDiv);

                                        this.EpicSchedulingDataService.GetProviderData(providerId).then((response) => {
                                            let profiles: Profiles = response.Value.Profiles;
                                            let epicId: string = profiles.EpicID ? ExtractContent(profiles.EpicID.ProfileData) : '';
                                            epicId = epicId.toUpperCase(); // Letters in id need to be capitalized.
                                            let departmentId: string = profiles.DepartmentID ? ExtractContent(profiles.DepartmentID.ProfileData) : '';
                                            departmentId = departmentId.replace(' ', '');
                                            let visitTypes: string = profiles.VisitType ? ExtractContent(profiles.VisitType.ProfileData) : '';
                                            if (!!epicId && !!visitTypes) {
                                                let visitTypesArray = visitTypes.split(',');

                                                if (this.Configuration.MultipleVisitTypesMessage && visitTypesArray.length > 1) {
                                                    // Prepend multiple visit types message.
                                                    let message: HTMLElement = document.createElement('h3');
                                                    message.className = 'MultipleVisitTypesMessage';
                                                    message.innerHTML = this.Configuration.MultipleVisitTypesMessage;
                                                    outerContainer.insertBefore(message, innerContainerDiv);
                                                }

                                                visitTypesArray.forEach((item) => {
                                                    let visitTypeArray: Array<string> = item.split('[');
                                                    let vtId: string = visitTypeArray[0].replace(/\s+/g, '');
                                                    // Get label if exists.
                                                    let vtLabel: string = visitTypeArray.length > 1 ? visitTypeArray[1].trim().replace(/(^.*\[|].*$)/g, '') : null;

                                                    if (vtLabel && visitTypeArray.length > 1) {
                                                        outerContainer.insertBefore(
                                                            this.EpicSchedulingService.HandleMultipleVisitTypes(epicId, vtId, vtLabel, departmentId, enabled),
                                                            innerContainerDiv
                                                        )
                                                    }
                                                });

                                                // Wrap buttons in container div.
                                                let buttons: Element[] = Array.from(document.getElementsByClassName('VisitTypeButton'));
                                                if (buttons.length) {
                                                    const wrapper = document.createElement('div');
                                                    wrapper.classList.add('VisitTypeButtonContainer');
                                                    buttons.forEach(button => wrapper.appendChild(button));
                                                    innerContainerDiv.appendChild(wrapper);
                                                }

                                                if (visitTypesArray.length === 1) {
                                                    innerContainerDiv.appendChild(this.EpicSchedulingService.GenerateEpicSchedulingWidget(enabled));
                                                    this.EpicSchedulingService.CallRenderWidgetScript(epicId, visitTypes, departmentId);
                                                    hasRendered = true;
                                                }

                                            }

                                        });
                                    } else {
                                        // Show message.
                                        outerContainer.innerHTML = `<p class="EpicDisabledMessage">${this.Configuration.EpicDisabledMessage}</p>`;
                                    }
                                } else {
                                    // Check for locations.
                                    let locationData = vitalSiteObject.taxonomy.find((facet) => {
                                        return facet.FacetPropertyName === 'LocationType';
                                    });
                                    if (locationData) {
                                        // Get ids.
                                        let container = document.getElementById(this.Configuration.OuterContainerId);
                                        let epicIds: string = container.hasAttribute('data-additionalparams-id') ? container.getAttribute('data-additionalparams-id').trim() : '';
                                        epicIds ? epicIds.toUpperCase() : '';
                                        let vtIds: string = container.hasAttribute('data-additionalparams-vt') ? container.getAttribute('data-additionalparams-vt').trim() : '';
                                        let departmentIds: string = container.hasAttribute('data-additionalparams-dept') ? container.getAttribute('data-additionalparams-dept').trim() : '';

                                        if (epicIds || vtIds || departmentIds) {
                                            container.appendChild(this.EpicSchedulingService.GenerateEpicSchedulingWidget(true));
                                            this.EpicSchedulingService.CallRenderWidgetScript(epicIds, vtIds, departmentIds);
                                            hasRendered = true;
                                        } else {
                                            console.log('Check Epic widget has correct attributes.');
                                        }
                                    }
                                }
                            }
                            // Handle standalone Multiple Visit Types.
                            if (!hasRendered) {
                                let visitTypeContainer: HTMLCollectionOf<Element> = document.getElementsByClassName('VisitTypeButtonContainer');
                                if (visitTypeContainer) {
                                    let visitTypeContainerArray = Array.from(visitTypeContainer);
                                    visitTypeContainerArray.forEach(container => {
                                        let visitTypeButtons: HTMLCollectionOf<HTMLButtonElement> = container.getElementsByTagName('button');
                                        let visitTypeButtonsArray = Array.from(visitTypeButtons);
                                        visitTypeButtonsArray.forEach(button => {
                                            button.addEventListener('click', event => {
                                                let element = event.currentTarget as HTMLButtonElement;
                                                let epicId: string = element.dataset.epicId;
                                                let vtId: string = element.dataset.visitType;
                                                let departmentId: string = element.dataset.departmentId;
                                                let enabled: boolean = true;
                                                this.EpicSchedulingService.HandleMultipleVisitButtonClick(event, epicId, vtId, departmentId, enabled);
                                            });
                                        })
                                    })
                                }
                            }
                        } else {
                            // Display standalone widget.
                            // Get required attributes from div.
                            let epicId: string = embedContainer.getAttribute('data-additionalparams-id');
                            epicId ? epicId.toUpperCase() : '';
                            let vtId: string = embedContainer.getAttribute('data-additionalparams-vt');
                            let departmentId: string = embedContainer.getAttribute('data-additionalparams-dept');

                            if (epicId || vtId || departmentId) {
                                this.EpicSchedulingService.CallRenderWidgetScript(epicId, vtId, departmentId);
                                hasRendered = true;
                            } else {
                                console.log('Check Epic widget has correct attributes added to the embed div.');
                            }
                        }
                    }).catch(error => {
                        console.log(error);
                    });
                } else {
                    console.log('Check EpicHost and EpicInstance are set in the config.');
                }
            }
        } else {
            console.log('Check ApiKey, WidgetType and ScriptPath are set in the config.');
        }
    }

}
